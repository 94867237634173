<template lang="pug">
	div.default-modal.subsource-modal
		.modal-header(v-if="subsource")
			.modal-title Rules: [{{ subsource.id }}] {{ subsource.sub_id }}
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body(v-if="subsource")
			.modal-body-inner
				.ov-table-wrapper
					table.table.table-bordered
						thead
							tr
								th.text-center Weight
								th From
								th To Type
								th To Value
								th &nbsp;
						tbody
							tr(v-if="!rules.length")
								td(colspan="5") No matching routes
							tr(v-for="(r,i) in rules", v-bind:key="i")
								td(style="width:80px")
									input.form-control(type="text", v-model.number="r._weight", :disabled="!r._edit", style="width:80px;text-align: center;")
								td Subsource [{{ subsource.id }}]
								td
									select.form-control(v-model="r._action", name="action", :disabled="!r._edit")
										option(v-for="o in actionOptions", :value="o.v") {{ o.t }}
								td
									input.form-control(type="text", v-model="r._to_offer_id", placeholder="OfferID",
										v-if="r._action=='offer'", :disabled="!r._edit", style="width:80px;text-align: center;")
									input.form-control(type="text", v-model="r._to_placement_id", name="to_placement_id",
										placeholder="PlacementID", v-if="r._action=='placement'", :disabled="!r._edit",
										style="width:80px;text-align: center;")
								td.actions
									span(v-if="!r._edit")
										a.btn.btn-action-default(@click="startEdit(r)", v-b-tooltip.hover.bottom, title="Edit")
											i.la.la-edit
										a.btn.btn-action-default(@click="removeRule(r, i)", v-b-tooltip.hover.bottom, title="Remove")
											i.la.la-times
									span(v-if="r._edit")
										a.btn.btn-action-default(@click="cancelEdit(r)", v-b-tooltip.hover.bottom, title="Cancel Edit")
											i.la.la-times
										a.btn.btn-action-default(@click="saveEdit(r)", v-b-tooltip.hover.bottom, title="Save")
											i.la.la-save
				button.btn.btn-secondary(type="button", @click="add()")
					i.la.la-plus
					| Add
</template>
<style lang="scss">
.subsource-modal {
  .ov-table-wrapper {
    table.table.table-bordered {
      thead {
        tr {
          th {
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 3px;
            line-height: 30px;

            .form-control {
              font-size: 12px;
              background: #fff;

              &:disabled {
                background: #f6f6f6;
                cursor: no-drop;
              }
            }

            &.actions {
              .btn {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import Vue from 'vue';

export default {
  name: 'SubsourceRulesModal',
  props: [
    'id',
    // 'publisher',
    // 'placement',
    // 'subsource',
    // 'rules',
  ],
  data() {
    return {
      busy: false,
      publisher: null,
      placement: null,
      subsource: null,
      rules: [],
      actionOptions: [
        { v: 'global_fallback', t: 'Global Fallback (default)' },
        { v: 'offer', t: 'Offer' },
        { v: 'placement', t: 'Placement' },
        { v: 'smartlink', t: 'Smartlink' },
        //{v: 'subsource', t: 'Change Subsource'},
        { v: 'block', t: 'Block' },
      ],
    };
  },
  methods: {
    startEdit(r) {
      if (r.automatic) {
        return;
      }
      r._edit = true;
    },
    cancelEdit(r) {
      r._edit = false;
      r._weight = r.weight;
      r._action = r.action;
      r._to_offer_id = r.to_offer_id;
      r._to_placement_id = r.to_placement_id;
    },
    add() {
      this.rules.push({
        _edit: true,
        weight: 0,
        id: 0,
        action: 'offer',
        automatic: false,
        placement_id: this.subsource.placement_id,
        from_subsource_id: this.subsource.id,
        from_subsource_id2: null,
        to_subsource_id: null,
        to_placement_id: null,
        to_offer_id: null,
        _weight: 0,
        _action: 'offer',
        _to_offer_id: null,
        _to_placement_id: null,
      });
    },
    calcStatus() {
      let sum = 0;
      this.rules.forEach((r) => {
        sum += r.weight;
      });
      this.subsource.status = sum >= 100 ? 'blocked' : sum > 0 ? 'custom' : 'open';
    },
    async saveEdit(r) {
      let params = {
        placement_id: this.subsource.placement_id,
        sub1_id: this.subsource.id,
        id: r.id,
        weight: r._weight,
        action: r._action,
        to_placement_id: r._to_placement_id,
        to_offer_id: r._to_offer_id,
      };
      try {
        let res = await this.$ovReq.post('placement/saveRoute', params);
        r.id = res.entity.id;
        r.weight = res.entity.weight;
        r.action = res.entity.action;
        r.to_placement_id = res.entity.to_placement_id;
        r.to_offer_id = res.entity.to_offer_id;
        this.cancelEdit(r);
      } catch (e) {
        console.error(e);
      }
      this.calcStatus();
    },
    async removeRule(r, index) {
      if (!r.id) {
        this.rules.splice(index, 1);
        return;
      }
      let params = {
        id: r.id,
      };
      try {
        let res = await this.$ovReq.post('placement/removeRoute', params);
        console.log(res);
        this.rules.splice(index, 1);
      } catch (e) {
        console.error(e);
      }
      this.calcStatus();
    },
  },
  mounted() {
    let vm = this;
    vm.$nextTick(async () => {
      if (vm.id) {
        console.log('Got ID', vm.id);
        let subsourceInfo = await vm.$ovData.subsource.getInfo(vm.id);
        if (subsourceInfo) {
          vm.subsource = subsourceInfo.subsource;
          vm.publisher = subsourceInfo.publisher;
          vm.placement = subsourceInfo.placement;
          vm.rules = subsourceInfo.rules;
          vm.rules.forEach((r) => {
            Vue.set(r, '_edit', false);
            Vue.set(r, '_weight', r.weight);
            Vue.set(r, '_action', r.action);
            Vue.set(r, '_to_offer_id', r.to_offer_id);
            Vue.set(r, '_to_placement_id', r.to_placement_id);
          });
        }
      } else {
        vm.rules.forEach((r) => {
          Vue.set(r, '_edit', false);
          Vue.set(r, '_weight', r.weight);
          Vue.set(r, '_action', r.action);
          Vue.set(r, '_to_offer_id', r.to_offer_id);
          Vue.set(r, '_to_placement_id', r.to_placement_id);
        });
      }
    });
  },
};
</script>
